<script setup>
import { defineAsyncComponent } from 'vue';

const props = defineProps({
    type: {
        type: String,
        default: 'accent'
    },
    hClass: {
        type: String,
        default: 'h-8',
    }
});

const Logo = defineAsyncComponent(() => {

    switch (props.type) {
        case 'primary':
            return import('@/../images/logo-primary.svg');
        case 'white':
            return import('@/../images/logo-white.svg');
        default:
            return import('@/../images/logo-accent.svg');
    }
});

</script>

<template>
    <Logo :class="props.hClass"/>
</template>
