<script setup>
import { Head, Link, usePage } from '@inertiajs/vue3';
import Alert from '@/Components/Alert.vue';
import { computed } from 'vue';

const props = defineProps({
    type: {
        type: String,
        default: 'bg-200'
    },
    paddingClass: {
        type: String,
        default: 'px-4'
    }
});

const page = usePage();

const modes = computed(() => page.props.modes);

const error = computed(() => page.props.flash.error);
const success = computed(() => page.props.flash.success);
const info = computed(() => page.props.flash.info);

const typeClass = computed(() => {
    switch (props.type) {
        case 'primary':
            return 'bg-primary text-primary-content';
        default:
            return 'bg-base-200';
    }
});

</script>

<template>
    <Head :title="$t('auth.default_title')" />

    <div v-if="error" class="toast toast-bottom toast-end z-50">
        <Alert type="error" :dismissable="true" :message="error"></Alert>
    </div>

    <div v-if="success" class="toast toast-bottom toast-end z-50">
        <Alert type="success" :dismissable="true" :message="success"></Alert>
    </div>

    <div v-if="info" class="toast toast-bottom toast-end z-50">
        <Alert :dismissable="true" :message="info"></Alert>
    </div>

    <div class="-mb-4 min-h-screen w-full relative overflow-x-hidden" :class="[ typeClass, props.paddingClass, { 'pt-14' : modes.preLaunch } ]">
        <Link v-if="modes.preLaunch" :href="route('newsletter.pre-launch')" class="bg-primary py-2 px-4 w-full text-primary-content text-sm text-center fixed top-0 left-0 z-10">
            {{ $t('pre_launch.banner') }}
        </Link>
        <slot></slot>
    </div>
</template>
